.principal {
    height: 50px !important;
    top: 0 !important;
    width: 100% !important;
    margin-left: 0px !important;
    background-color: #000722 !important;
    position: fixed;
}

.logo {
    margin-left: -10px;
    margin-top: 5px;
}

.nav-links {
    padding-top: 15px !important;
}

.principal-floatRight {
    height: 50px !important;
    top: 0 !important;
    width: 20% !important;
    margin-left: 78% !important;
    float: right !important;
    background-color: transparent !important;
    position: fixed;
    display: flex;
    justify-content: flex-end;
}

#nav-li {
    display: inline;
    border-right: 1px solid #bbb;
}

#nav-li:last-child {
    border-right: none;
}

#nav-item {
    color: white;
    padding-left: 8px;
    padding-right: 8px;
}

#nav-item:hover {
    background-color: #1D7391;
}

#div-degustation-message {
    position: fixed;
    right: -7%;
    top: 5px;
    height: 50px;
    width: 520px;
}

.perfilbtn {
    position: fixed;
    cursor: pointer;
    right: 15px;
    top: 8px;
}

.Itens {
    width: 175px;
    flex-direction: column;
    background: #000722;
    display: flex;
    border-radius: 0px 0px 5px 5px;
    position: fixed;
    top: 50px;
    right: 0;
}

.Itens a {
    color: white;
    line-height: 2.5;
    height: 35px;
    cursor: pointer;
}

.Itens a:hover {
    background-color: #000722 !important;
    border-radius: 5px;
}

#nomeUsuarioLogado {
    display: flex;
    align-items: center;
    align-self: flex-end;
    /* margin-right: 5%; */
    color: white;
    margin-top: 5%;
}

.sidenav---sidenav---_2tBP {
    /* background-color: blue !important; */
    background-color: #000722 !important;
}

.sidenav---sidenav-subnav---1EN61 {
    /* background-color: blue !important; */
    background-color: #000722 !important;
}

.sidenav---sidenav-subnavitem---1cD47 {
    color: white !important;
}

.sidenav---navitem---9uL5T {
    cursor: pointer !important;
    color: white !important;
}

.sidenav---navitem---9uL5T:hover {
    /* background-color: green !important; */
    background-color: #000722 !important;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
    min-width: 220px !important;
}

.sidenav---sidenav---_2tBP {
    position: fixed;
}

.iconeMenu {
    font-size: 1.75em !important;
    color: white !important;
}

.painelCardManutencoes {
    background-color: #3f51b5 !important;
    border-color: #3f51b5 !important;
    border: 2px solid #3f51b5 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    text-transform: initial !important;
    margin-bottom: 1% !important;
    width: 100%;
    border-radius: 5px !important;
    box-shadow: 5px 4px 10px #cbcbcb91 !important;
}

.painelCardOficinas {
    background-color: #673ab7 !important;
    border-color: #673ab7 !important;
    border: 2px solid #673ab7 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    text-transform: initial !important;
    margin-bottom: 1% !important;
    width: 100%;
    border-radius: 5px !important;
    box-shadow: 5px 4px 10px #cbcbcb91 !important;
}

.painelCardVeiculos {
    background-color: #009688 !important;
    border-color: #009688 !important;
    border: 2px solid #009688 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    text-transform: initial !important;
    margin-bottom: 1% !important;
    width: 100%;
    border-radius: 5px !important;
    box-shadow: 5px 4px 10px #cbcbcb91 !important;
}

.iconedashboard {
    font-size: 55px;
    color: white;
    margin-right: 65px;
}

.content {
    padding: 10px!important;
    background-color: #fff;
}

#card {
    text-decoration: none !important;
    outline: none !important;
}

#card a:hover {
    text-decoration: none;
    outline: none !important;
}

#btn-card:focus {
    outline: none !important;
}

.btn-sucesso {
    color: white !important;
    background-color: rgb(2, 177, 2) !important;
    border: 1px solid rgb(2, 177, 2) !important;
}

.btn-sucesso:hover {
    color: white !important;
    background-color: green !important;
    border-color: green !important;
}

.btn-sucesso:disabled {
    color: white !important;
    background-color: rgb(2, 177, 2) !important;
    border: 1px solid rgb(2, 177, 2) !important;
}

.btn-sucesso:disabled:hover {
    color: white !important;
    background-color: green !important;
    border-color: green !important;
}

#iconepesquisar {
    position: absolute;
    padding: 10px 12px;
    pointer-events: none;
}

#homeHeader {
    display: flex;
    margin: auto;
    margin-bottom: 30px;
    margin-top: 20px;
    margin-left: -15px;
}

#btn-novo {
    width: auto;
}

#input-search {
    margin-left: 0px;
}

@media (max-width: 590px) {
    #homeHeader {
        display: block;
    }
    #btn-novo {
        padding: auto;
        width: 100%;
    }
    #input-search {
        margin-left: -5px;
        margin-top: 10px;
    }
}

.rt-thead {
    font-weight: bold;
}

.bottomNovoVeiculo {
    margin-bottom: 1%;
}

.conteudoEmLinha {
    display: flex;
    flex-direction: row;
}

.heightModal {
    width: 90%;
    max-width: none;
    margin-top: 4%;
}

.footerManutencao {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

#tittle-Detalhes{
    font-size: 14px;
    margin-bottom: 0px;
    text-transform: uppercase;
}

/* --- COLAPSE COMPONENT - START --- */

.collapse-container {
    border: 1px solid #000722;
    padding: 0px;
    margin-bottom: 15px;
}

.collpase-header {
    background-color: #000722;
    color: #fff;
    width: 100%;
    text-align: left;
    border: none;
}

.collpase-icon {
    font-size: 20px;
}

.collpase-title {
    margin-left: 5px;
    font-size: 15px;
}

.collapse-body {
    padding-bottom: 10px;
}

.collapse-footer {
    padding: 0;
}

/* --- COLAPSE COMPONENT - END --- */
#botaoReactTable {
    padding: 1px 14px;
    text-transform: none;
    margin-left: 2vh;
    border: none;
    width: 90%;
}

#botaoReactTable:hover {
    padding: 1px 14px;
    text-transform: none;
    margin-left: 2vh;
    border: none;
    opacity: 0.9;
}
