.auto-login {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.auto-login p {
  text-align: center;
  line-height: 60px;
}

.auto-login img {
  width: 25%;
}