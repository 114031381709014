.menu {
    height: 80px;
    background-color: white !important;
    color: #000722;
    display: flex;
    flex-direction: row !important;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 200%;
}

.header {
    height: 50px;
    background-color: #000722 !important;
    color: white;
    display: flex;
    flex-direction: row !important;
    text-align: center;
}

#btn-menu {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 0.1px solid #0007220d;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f7f7f7
}

.painelCardManutencoes {
    background-color: #3f51b5 !important;
    border-color: #3f51b5 !important;
    border: 2px solid #3f51b5 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    text-transform: initial !important;
    margin-bottom: 10% !important;
    width: 100%;
    border-radius: 5px !important;
    box-shadow: 5px 4px 10px #cbcbcb91 !important;
    height: 100px;
}

.painelCardOficinas {
    background-color: #673ab7 !important;
    border-color: #673ab7 !important;
    border: 2px solid #673ab7 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    text-transform: initial !important;
    margin-bottom: 10% !important;
    width: 100%;
    border-radius: 5px !important;
    box-shadow: 5px 4px 10px #cbcbcb91 !important;
    height: 100px;
}

.painelCardVeiculos {
    background-color: #009688 !important;
    border-color: #009688 !important;
    border: 2px solid #009688 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    text-transform: initial !important;
    margin-bottom: 10% !important;
    width: 100%;
    border-radius: 5px !important;
    box-shadow: 5px 4px 10px #cbcbcb91 !important;
    height: 100px;
}

.iconedashboard {
    font-size: 55px;
    color: white;
    margin-right: 80px;
}

.informacoesCard {
    margin-top: -18%;
    font-size: 14px;
}

.textCard {
    color: white;
    margin-top: -7%;
}

.icone :hover {
    color: #001463f0;
    cursor: pointer;
}

.icone :focus {
    color: #001463f0;
    cursor: pointer;
}

.espacamentoCard {
    margin-bottom: 7%;
}

.detalhesmanutencao {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: -6%
}

.nav-tabs .nav-link.active {
    color: #007bff !important;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    outline: none !important;
}

.nav-tabs .nav-link {
    color: #495057 !important;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    outline: none !important;
}

.margintopHr{
    margin-top: 0px;
}

#loading{
    display: flex;
    justify-content: center;
    font-size: 90px;
    margin-top: 15%;
}

.btn-aviso{
    width: 50%;
    font-size: 12px;
}

#div-aviso-message {
    position: fixed;
    right: -3%;
    top: 9px;
    height: 50px;
    width: 485px;
}